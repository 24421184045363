import * as yup from 'yup';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const datosSchema = yup.object().shape({
    tipoParticipante: yup.mixed().nullable(false).required('El dato es obligatorio'),

    documento: yup.string().min(6, 'Debe contener 6 caracteres como mínimo').required('El dato es obligatorio'),
    expedido: yup.mixed().nullable(false).required('El dato es obligatorio'),
    nombres: yup.string().required('El dato es obligatorio'),
    // apellidoPaterno: yup.string().required('El apellido paterno es obligatorio'),
    // apellidoMaterno: yup.string().required('El dato es obligatorio'),
    // apellidoPaterno: yup.string().when('apellidoMaterno', {
    //     is: null,
    //     then: (schema) => schema.required('El dato es obligatorio'),
    //     otherwise: (schema) => schema.nullable(true),
    // }),

    sexo: yup.mixed().nullable(false).required('El dato es obligatorio'),
    fechaNacimiento: yup.date().required('El dato es obligatorio'),
    estadoCivil: yup.mixed().nullable(false).required('El dato es obligatorio'),

    paisOrigen: yup.mixed().nullable(false).required('El dato es obligatorio'),
    departamentoOrigen: yup.mixed().nullable(false).required('El dato es obligatorio'),
    paisResidencia: yup.mixed().nullable(false).required('El dato es obligatorio'),
    departamentoResidencia: yup.mixed().nullable(false).required('El dato es obligatorio'),
    municipioResidencia: yup.mixed().nullable(false).required('El dato es obligatorio'),

    direccion: yup.string().required('El dato es obligatorio'),
    // telefono: yup.number().nullable(true).typeError('El número de celular debe ser numérico'),
    celular: yup.number().typeError('El número de celular debe ser numérico').min(8, 'Debe contener 7 números como mínimo').required('El dato es obligatorio'),
    // correo: yup.string().email('Escriba un correo válido').required('El correo es obligatorio'),
    correo: yup.string().matches(emailRegex, 'Escriba un correo válido').required('El dato es obligatorio'),

    universidad: yup.mixed().required('El dato es obligatorio'),
    areaEstudio: yup.mixed().required('El dato es obligatorio'),
    gradoObtenido: yup.mixed().required('El dato es obligatorio'),

    fechaDiploma: yup.date().required('El dato es obligatorio'),
    fechaTitulo: yup.date().required('El dato es obligatorio'),


    // version: yup.number().required('La versión es obligatoria').min(0, 'Debe ser mayor que 0'),
    // totalCreditos: yup.number().required('el Total de créditos es obligatorio').min(0, 'Debe ser mayor que 0'),
    // objetivoGeneral: yup.string().required('El objetivo general es obligatorio'),
    // requisitosAdmision: yup.string().min(50, 'Debe contener 50 caracteres como mínimo').required('Los requisitos de admisión son obligatorios'),
    // fechaInicio: yup.date().required('La fecha de inicio es obligatoria'),
    // fechaFin: yup
    //     .date()
    //     .required('La fecha de finalización es obligatoria')
    //     .min(yup.ref('fechaInicio'), 'La fecha final debe ser posterior a la fecha de inicio'),
    // duracionColegiatura: yup.number().required('La duración de la colegiatura es obligatoria').min(0, 'Debe ser mayor que 0'),
    // tiempoInvestigacion: yup.number().required('El tiempo de investigación es obligatorio').min(0, 'Debe ser mayor que 0'),
    // certificadoAcademico: yup.string().min(10, 'Debe contener 10 caracteres como mínimo').required('El certificado o título es obligatorio'),
    // trabajoInvestigacion: yup.mixed().nullable(false).required('El trabajo de investigación es obligatorio'),
    // graduacion: yup.mixed().nullable(false).required('Seleccione una opción'),
    // nivelPrograma: yup.mixed().nullable(false).required('Seleccione una opción'),
    // destinariosPrograma: yup.mixed().nullable(false).required('Seleccione una o varias opciones'),
    // resolucion: yup.string().min(6, 'Debe contener 6 caracteres como mínimo').required('La resolución es obligatoria'),
    // dictamen: yup.string().min(6, 'Debe contener 6 caracteres como mínimo').required('El dictamen es obligatorio'),
    
    // facultadesPrograma: yup.mixed().nullable(false).required('Seleccione una opción'),
});