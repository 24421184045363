import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticatedGuard } from './authGuard';

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('../modules/login/FormLogin.vue'),
	},
	{
		path: '/',
		component: () => import('../components/Dashboard.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/programas/lista',
		name: 'lista-programa',
		component: () => import('../modules/programas/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/inscripciones/lista',
		name: 'lista-inscripciones',
		component: () => import('../modules/inscripcion/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	{
		path: '/pagos/lista',
		name: 'pagos-lista',
		component: () => import('../modules/pagos/Lista.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},
	{
		path: '/pagos/kardex-programa',
		name: 'kardex-programa',
		component: () => import('../modules/pagos/FormPagosEstudiantePrograma.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	// {
	// 	path: '/pagos/no-adeudo',
	// 	name: 'no-adeudo',
	// 	component: () => import('../modules/pagos/FormNoAdeudo.vue'),
	// 	beforeEnter: [isAuthenticatedGuard],
	// },

	{
		path: '/perfil',
		name: 'perfil',
		component: () => import('../modules/estudiantes/FormPerfil.vue'),
		beforeEnter: [isAuthenticatedGuard],
	},

	// Rutas Públicas

	// ruta para acceder al formulario de publico de inscripcion
	{
		path: '/inscripcion/programa/:id/:inscripcionId?',
		name: 'inscripcion-publico',
		component: () => import('../modules/inscripcion/FormInscripcionPublico.vue'),
	},

	// evaluaciones
	{
		path: '/evaluaciones/lista',
		name: 'lista-evaluaciones',
		component: () => import('../modules/evaluaciones/Lista.vue'),
	},
	{
		path: '/evaluaciones/detalle/:id',
		name: 'detalle-evaluacion',
		component: () => import('../modules/evaluaciones/Detalle.vue'),
	},

	{
		path: '/:pathMatch(.*)*',
		redirect: { name: 'login' },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return { left: 0, top: 0 };
	},
});

export default router;
